.projects-section {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    padding: 20px;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .projects-heading {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .projects-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .project-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .project-title {
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
  }
  
  .project-iframe {
    width: 100%;
    height: 500px; /* Increased height for better visibility */
    border: none;
    margin-top: 10px;
    border-radius: 8px;
  }
  
/* Footer Styling */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
  font-family: "PoetsenOne", sans-serif;
  font-size: 20px;
  padding: 10px 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid white;
}

/* Left Section (Text) */
.footer-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.footer-text {
  animation: fadeInOut 3s ease-in-out infinite;
}

/* Right Section (Social Icons) */
.footer-right {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icon {
  color: white;
  font-size: 24px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  color: #EC38BC;
  transform: scale(1.2);
}

/* Blinking Animation */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

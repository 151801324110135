.blogs-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    padding: 20px;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .blogs-heading {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
  }
  
  .coming-soon h2 {
    font-size: 28px;
    color: white;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
.skills-section {
    background: linear-gradient(120deg, #6a11cb, #2575fc);
    padding: 50px 0;
    color: white;
  }

  .skills-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    padding: 20px;
  }
  
  .skills-heading {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
  }
  
  .skills-category {
    margin-bottom: 40px;
  }
  
  .category-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .skill-card {
    background-color: #ffffff;
    border: none;
    transition: transform 0.3s;
    text-align: center;
    border-radius: 10px;
  }
  
  .skill-card:hover {
    transform: scale(1.1);
  }
  
  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #f8f9fa;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .skill-image {
    height: 100px;
    width: auto;
  }
  
  .skill-name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    color: black;
  }
  
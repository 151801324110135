/* Navbar Styling */
.navbar {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.navbar-buttons {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    flex: 1;
}

.navbar-button {
    position: relative;
    margin: 0;
    padding: 0 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
}

/* Style Link elements like buttons */
.navbar-button a {
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    display: inline-block;
    transition: all 0.3s ease;
}

.navbar-button a:hover {
    color: black;
    background-color: white;
    border-radius: 5px;
}

/* Separator line between buttons */
.navbar-button:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: white;
}

/* Navbar Right Section */
.navbar-right {
    color: white;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    margin-right: 10px;
}

/* Responsive for smaller screens */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 10px 20px;
    }

    .navbar-buttons {
        flex-direction: column;
        width: 100%;
    }

    .navbar-button {
        width: 100%;
        text-align: left;
        padding: 10px 0;
    }

    .navbar-right {
        margin-top: 10px;
        align-self: flex-start;
    }

    .navbar-button:not(:last-child)::after {
        display: none;
    }
}

.contact-section {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    padding: 20px;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-heading {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .social-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensures 3 cards in a row */
    gap: 30px; /* Adds space between the cards */
    width: 90%;
    margin-top: 20px;
  }
  
  @media (max-width: 992px) {
    .social-cards {
      grid-template-columns: repeat(2, 1fr); /* For medium screens, display 2 cards in a row */
    }
  }
  
  @media (max-width: 768px) {
    .social-cards {
      grid-template-columns: 1fr; /* For smaller screens, display 1 card per row */
    }
  }
  
  .social-card {
    background-color: white;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    text-decoration: none;
    color: black;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .social-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .social-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .social-name {
    font-size: 18px;
    font-weight: bold;
  }
  
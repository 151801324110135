/* Education Section */
.education-section {
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    padding: 50px 0;
    font-family: 'Pattaya', sans-serif;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .education-heading {
    margin: 10px;
    font-family: "Dancing Script", cursive;
  font-size: 36px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .education-cards .education-card {
    width: 100%;
    transition: transform 0.3s ease;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
  
  .education-cards .education-card:hover {
    transform: scale(1.05);
  }
  
  .card-title {
    font-weight: bold;
    color: #333;
  }
  
  .card-body {
    font-size: 14px;
  }
  
  .card-body strong {
    color: #333;
  }
  
  .card-body {
    background: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .card-body p {
    color: #333;
  }
  
  .education-logo, .university-logo {
    width: 120px; /* Adjust size as per requirement */
    height: auto;
    margin-top: 20px;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .education-cards .education-card {
      width: 100%;
    }
  }
  
/* Entire Page Styling */
.resume-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease infinite;
  padding: 20px;
}

/* Title Styling */
.resume-title-container {
  margin-bottom: 20px;
}

.resume-title {
  font-family: "Dancing Script", cursive;
  font-size: 36px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Iframe Styling */
.resume-iframe-container {
  width: 100%;
  max-width: 900px;
  height: 70vh;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}

.resume-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Button Styling */
.resume-button-container {
  margin-top: 10px;
}

.animated-button {
  background: black;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.animated-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Background Animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

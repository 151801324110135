/* LandingPage.css */

/* Landing Page Styling */
.landing-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    font-family: 'Pattaya', sans-serif;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.center-box {
    width: 60%;
    text-align: left;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

h1 {
    color: black;
    font-weight: bold;
}

.highlight {
    color: white;
    background: black;
    padding: 0 5px;
    border-radius: 3px;
}

.dual-box {
    display: flex;
    width: 60%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.left-section {
    flex: 65%;
    padding-right: 20px;
    font-family: 'PoetsenOne', sans-serif;
}

.left-section h2 {
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
}

.left-section p {
    color: black;
    font-weight: bold;
}

.right-section {
    flex: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-picture {
    width: 100px;
    height: 100px;
    background: gray; /* Placeholder for the picture */
    border-radius: 50%;
    background-image: url('a.jpeg');
    background-size: cover;
    background-position: center;
}

.divider {
    margin: 20px 0;
    border: 0;
    border-top: 5px solid #000000;
}

.intro-text {
    font-family: 'Pavanam', sans-serif;
    font-size: 14px;
    color: black;
    line-height: 1.6;
    margin-top: 20px;
}

/* New Buttons Section */
.buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
    width: 60%;
}

.landing-button {
    background-color: black; /* Initial background color */
    color: white; /* Initial font color */
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-weight: bold;
    transition: color 0.3s ease;
}

.landing-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: white; /* Background color when hovered */
    transition: left 0.5s ease;
    z-index: 0;
}

.landing-button:hover {
    color: white; /* Font color changes to black on hover */
}

.landing-button:hover:before {
    left: 100%; /* Move the white color from left to right */
}

.landing-button span {
    position: relative;
    z-index: 1; /* Ensure text is above the background animation */
}

/* Responsive for smaller screens */
@media (max-width: 768px) {
    .dual-box {
        flex-direction: column;
        width: 90%;
    }
    .left-section, .right-section {
        flex: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }
    .buttons-container {
        width: 100%;
    }
}

/* Existing styles remain the same unless overridden */

.landing-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #7303C0, #EC38BC, #FDEFF9);
    background-size: 300% 300%;
    animation: gradientAnimation 6s ease infinite;
    font-family: 'Pattaya', sans-serif;
}

.center-box, .dual-box {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.highlight {
    color: white;
    background: black;
    padding: 0 5px;
    border-radius: 3px;
}

.profile-picture {
    width: 200px;
    height: 200px;
    background: gray;
    border-radius: 50%;
    background-image: url('a.jpeg');
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}

/* Bootstrap integration overrides */
.buttons-container .col-auto {
    padding: 0 5px;
}

/* Responsive */
@media (max-width: 768px) {
    .dual-box {
        flex-direction: column;
    }
    .profile-picture {
        margin-bottom: 20px;
    }
}
